<template>
  <tr class="ant-medic-line">
    <td class="border-0 border-b border-solid border-gray-300 px-4 py-2 w-1/4">
      {{ antecedent.antecedent ? antecedent.antecedent.name : antecedent.name }}
      <p v-if="errorMsg" class="text-center text-red-500 p-2">{{ errorMsg }}</p>
    </td>
    <td class="border-0 border-b border-solid border-gray-300 px-4 py-2 w-3/12">
      <label class="capitalize">{{
        antecedent.startTreatmentDate
          ? moment()(antecedent.startTreatmentDate).format("D MMM  YYYY")
          : ""
      }}</label>
    </td>
    <td
      :class="
        'border-0 border-b border-solid border-gray-300 px-4 py-2 text-center font-semibold ' +
          (antecedent.followTreatment ? 'text-green-600' : 'text-gray-600')
      "
    >
      <label>{{ antecedent.followTreatment ? "OUI" : "NO" }}</label>
    </td>
    <td
      class="border-0 border-b border-solid border-gray-300 pl-4 pr-1 py-2 relative"
    >
      <label>{{ antecedent.moreInfo || "-" }}</label>

      <button
        @click="removeAntecedentMedical()"
        class="rmv-antcmed-btn absolute bg-transparent text-red-600 border-0 border-red-400 rounded-cu text-white cursor-pointer"
      >
        <i class="fa fa-trash"></i>
      </button>
    </td>
    <td class="w-1/12">
      <button
        v-if="isEditMode"
        @click="saveMoreInfoAntecedentMedical()"
        :class="' ml-2 border-0 rounded-cu text-white cursor-pointer'"
      >
        <i :class="'fas fa-' + saveBtn.icon"></i>
      </button>
      <!--
      <button @click="removeAntecedentMedical()" class="rmv-antcmed-btn bg-transparent text-red-600 border-0 border-red-400 py-2 px-2 mr-3 rounded-cu text-white cursor-pointer"><i class="fa fa-trash"></i></button> -->
      <!--
      <button  @click="isEditMode = true" class="rmv-antcmed-btn bg-transparent text-red-600 border-0 border-red-500 py-2 px-2 mr-3 rounded-cu text-white cursor-pointer"><i class="fas fa-edit"></i></button> -->
    </td>
  </tr>
</template>
<script>
const toggleInput = () => import("@/views/global-components/toggle");
import Datepicker from "vuejs-datepicker";
import { fr } from "vuejs-datepicker/dist/locale";
import moment from "moment";

export default {
  props: ["antecedent", "patient"],
  components: { toggleInput, Datepicker },
  data() {
    return {
      isEditMode: false,
      errorMsg: "",
      dateLangFR: fr,
      saveBtn: {
        color: "nblue",
        icon: "check"
      }
    };
  },

  methods: {
    onChange(newVal, sr) {
      const _this = this;
      setTimeout(function() {
        _this.assignAntecedentMedical();
      }, 400);
    },
    async ifLengthActive($event) {
      this.antecedent.isChecked = true;
      this.$forceUpdate();
      if (this.antecedent.moreInfo.length > 1) {
      }
    },
    saveMoreInfoAntecedentMedical() {
      // if(!this.antecedent.isChecked) {
      //   this.errorMsg = "Merci d'activer cet antecedent pour pouvoir sauvegarder";
      //   return
      // }
      this.assignAntecedentMedical();
    },
    removeAntecedentMedical() {
      // //console.log(this.antecedent)
      // return;
      this.errorMsg = "";
      this.saveBtn = {
        color: "yellow-500",
        icon: "sync"
      };
      this.$store.dispatch("healthbook/REMOVE_PATIENT_ANTECEDENT_MEDICAL", {
        patientId: this.patient._id,
        medId: this.antecedent._id,
        onData: this.onData
      });
    },
    async assignAntecedentMedical() {
      this.errorMsg = "";
      this.saveBtn = {
        color: "yellow-500",
        icon: "sync"
      };
      this.$store.dispatch("healthbook/SAVE_PATIENT_ANTECEDENT_MEDICAL", {
        patientId: this.patient._id,
        data: this.antecedent,
        onData: this.onData
      });
    },
    onData(data) {
      const el = this;
      setTimeout(function() {
        el.saveBtn = {
          color: "nblue",
          icon: "check"
        };
      }, 300);
      if (!this.antecedent.isChecked) {
        this.antecedent.moreInfo = "";
      }
      this.$emit("savedAntMedic", this.antecedent);
    },
    moment() {
      return moment;
    }
  }
};
</script>
<style lang="scss">
.rmv-antcmed-btn {
  display: none;
}
.ant-medic-line:hover {
  // background-color: #EEEEEE38;
  .rmv-antcmed-btn {
    position: absolute;
    top: calc(50% - 7.6px);
    right: 0px;
    display: inline-block;
  }
}
</style>
